<template>
  <div class="min-h-screen py-12 bg-hp-gray dark:bg-gray-900 dark:text-white">
    <div class="max-w-5xl px-4 mx-auto">
      <!-- Navigation -->
      <div class="flex justify-between items-center mb-40">
        <router-link to="/">
          <img src="@/assets/logos/Homeplan logo (new modern April 2022 - NO .com).svg" style="height: 2.1rem;" alt="Homeplan logo">
        </router-link>
        
       <router-link to="/" class="py-1.5 px-2.5 bg-blue-900 text-white transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none rounded-lg">
          Back to home
        </router-link>
      </div>

      <!-- Page heading -->
      <div class="text-6xl text-center font-bold mb-12">Do Not Sell My Personal Information</div>
      <div class="font-semibold text-center text-gray-500 dark:text-gray-300 text-lg mb-28">
        Homeplan does not sell your personal information. If you believe we have, please fill out this form and we will get in touch with you.
      </div>


      <!-- Form -->
      <form class="flex flex-col gap-8 pb-24" @submit.prevent="submitForm">

        <div>
          <label for="name">Full name:</label>
          <input v-model="form.data.name" type="text" id="name" class="mt-2 w-full px-3 py-2 dark:bg-gray-700  shadow-sm text-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 rounded-md" placeholder="Enter your full name..." required>
        </div>

        <div>
          <label for="email">Email:</label>
          <input v-model="form.data.email" type="email" id="email" class="mt-2 w-full px-3 py-2 dark:bg-gray-700  shadow-sm text-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 rounded-md" placeholder="Enter your email..." required>
        </div>

        <div>
          <label for="subject">Subject:</label>
          <input v-model="form.data.subject" type="text" id="subject" class="mt-2 w-full px-3 py-2 dark:bg-gray-700  shadow-sm text-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 rounded-md" placeholder="Enter subject...">
        </div>

        <div>
          <label for="body">Message body:</label>
          <textarea v-model="form.data.body" name="body" id="body" class="mt-2 w-full px-3 py-2 dark:bg-gray-700  shadow-sm text-sm sm:text-base border-1 border-gray-200 dark:border-gray-600 rounded-md" cols="30" rows="10" required />
        </div>

        <p v-if="form.response">{{ form.response }}</p>

        <p v-if="form.errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in form.errors" :key="error">{{ error }}</li>
          </ul>
        </p>

        <div class="flex mt-2 justify-end">
          <button type="submit" class="sm:w-auto w-full py-2 px-4 bg-hp-blue hover:bg-blue-700 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">Submit form</button>
        </div>

      </form>

      <Footer />
    </div>
  </div>
</template>


<script>
import Footer from "@/components/footer.vue";
import validator from 'validator';
import axios from "axios";

export default {
  components: {
    Footer
  },
  data() {
    return {
      form: {
        data: {
          name: '',
          email: '',
          subject: '',
          body: ''
        },
        errors: [],
        response: ''
      },
    }
  },
  methods: {
    async submitForm() {
      this.form.errors = [];
      this.form.response = '';

      var f = this.form.data;

      if (!f.name) {
        this.form.errors.push("Full name is required");
      } else if (validator.isAlphanumeric(f.name) == false) {
        validator.escape(f.name);
      }

      if (!f.email) {
        this.form.errors.push("Email is required");
      } else if (validator.isEmail(f.email) == false) {
        this.form.errors.push("Email is invalid");
      }

      if (!f.subject) {
        validator.escape(f.subject);
      }

      if (!f.body) {
        this.form.errors.push("Message body is required");
      } else if (validator.isAlphanumeric(f.body) == false) {
        validator.escape(f.body);
      }

      if (this.form.errors.length == 0) {
        axios.post(`${window.api}/users/submit_personal_information_form`, f)
          .then(() => {
            this.form.response = "Message sent successfully!";
          }).catch(() => {
            this.form.response = "There was an error submitting your request, please contact support.";
          })
      }
      

    },
    goBack() {
      window.history.back();
    }
  }
}
</script>
